<template>
  <div class="custom-slider relative">
    <!-- <button type="button" class="custom-slider-button prev" :class="{ show: index !== 0 && cards.length > 1}" @click="prev">
      <ion-icon name="chevron-back-outline" />
    </button>
    <button type="button" class="custom-slider-button next" :class="{ show: index !== cards.length - 1 && cards.length > 1}" @click="next">
      <ion-icon name="chevron-forward-outline" />
    </button> -->
    <div class="custom-slider-wrapper" ref="wrapper">
      <template v-for="(card, n) in cards">
        <div
          class="custom-slider-item w-full"
          :class="{ 'active': index === n, 'transform-right': n === index - 1 }"
          ref="item"
          :key="n"
          @click.prevent="click(n)"
        >
          <credit-card-4
            :card="activeCard"
            :name="user.name"
            exp-month="03"
            exp-year="22"
            :type="card.card_type"
            :credit-balance="(card.company_account.limit || 0) - (card.company_account.available_credit || 0)"
            :credit-limit="card.company_account.limit"
            :debit-balance="card.company_account.balance"
            :card-type="card.company_account && card.company_account.type"
            :card-status="status"
            style="width: 100%"
            @buttonClick="buttonClick"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery';

  export default {
    props: {
      cards: {
        type: Array,
        default: () => []
      },
      loading: {
        type: Boolean,
        default: false
      },
      status: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        index: null,
      }
    },
    computed: {
      activeCard() {
        return this.cards[this.index];
      },
    },
    watch: {
      index(index) {
        this.$emit('change', { card: this.activeCard, index });

        const element = this.$refs.item[index];

        const wrapper = this.$refs.wrapper;

        var left = element.offsetLeft;

        $(wrapper).animate({
          scrollLeft: left
        }, 300);
      }
    },
    mounted() {
      this.index = 0;
    },
    methods: {
      buttonClick() {
        this.$emit('buttonClick', { index: this.index, card: this.activeCard });
      },
      click(index) {
        this.index = index;
      },
      next() {
        this.index = this.index >= this.cards.length - 1 ? 0 : this.index + 1;
      },
      prev() {
        this.index = this.index <= 0 ? this.cards.length - 1 : this.index - 1;
      },
    }
  }
</script>
